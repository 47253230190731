import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconArrowRightMd(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M5 12h14m0 0l-6-6m6 6l-6 6"
			/>
		</Icon>
	);
}
